import { ReactElement } from 'react'

import { ThatchError } from '~/components/error/ThatchError'
import { Layout } from '~/components/layout/Layout'

const Thatch404 = () => {
  return (
    <ThatchError
      message="Sorry, page not found"
      isErrorBoundary={false}
    />
  )
}

Thatch404.getLayout = function getLayout(page: ReactElement) {
  return <Layout hideFooter>{page}</Layout>
}

export default Thatch404
